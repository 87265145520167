import React from 'react';
import { FaRobot } from 'react-icons/fa';
import { AiFillSmile } from 'react-icons/ai';

const Message = ({ text, isUser }) => {
    return (
      <div className={`message ${isUser ? 'user-message' : 'bot-message'}`}>
        <i className={`message-icon-${isUser ? 'user' : 'bot'}`}>
          {isUser ? <AiFillSmile /> : <FaRobot />}
        </i>
        <div className='message-text'>
          {text}
        </div>
      </div>
    );
  };

export default Message;