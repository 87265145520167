import React, { useRef, useEffect } from 'react';
import Message from './Message';

const MessageList = ({ messages }) => {
  const messageListRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom of the chat container when messages change.
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages]);

  if (!messages || messages.length === 0) {
    return <div>No messages to display.</div>;
  }

  return (
    <div className="message-list" ref={messageListRef}>
      {messages.map((message, index) => (
        <Message
          key={index}
          text={message.content}
          isUser={message.role === 'user'}
        />
      ))}
    </div>
  );
};

export default MessageList;