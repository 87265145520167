import React, { useState } from 'react';
import { RiSendPlane2Fill } from 'react-icons/ri';
import { IoIosCreate } from 'react-icons/io';

function Textbox( { inputText, onChange, onClick, onNewChatClick } ) {
        const [inputHeight, setInputHeight] = useState('auto');

        const handleInputChange = (e) => {
            onChange(e);
            adjustInputHeight(e.target);
        };

        const handleKeyDown = (e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault(); 
              onClick(); 
            }
          };

        const adjustInputHeight = (inputElement) => {
                const { scrollHeight, clientHeight} = inputElement;
                if (scrollHeight > clientHeight) {
                    setInputHeight(`${scrollHeight}px`);
                } else {
                    setInputHeight('auto')
                }
        };

        return (
            <div className='textbox-wrapper'>
                <div className="textbox-container">
                    <div className="textbox-input-container">
                        <textarea
                            type="text"
                            placeholder="Send a message..."
                            className="textbox-input"
                            value={inputText}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                            style={{ height: inputHeight }}
                        />
                    </div>
                    <button className="send-button" onClick={ onClick }> 
                        <i><RiSendPlane2Fill /></i>
                    </button>
                </div>
                <div>
                    <button className='new-chat-button' onClick={ onNewChatClick } >
                        <i><IoIosCreate />New Chat</i>
                    </button>
                </div>
            </div>
        );
    }

export default Textbox;
