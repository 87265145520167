import { v4 as uuidv4 } from 'uuid';

export const postChatDD = () => {
    const saveChat = async (message_recall, sessionId) => {
        const rid = uuidv4()
        const orgId = "45de80dc-0f19-4956-9523-99bc3586c7a6"
        const solutionId = "c68374f1-3fd7-4b9f-b96d-5227ff0f325a"
        const tableId = "chat_history"
        const post_url = `https://kdiqmco762.execute-api.us-east-1.amazonaws.com/default/organizations/${orgId}/solutions/${solutionId}/databases/default/tables/${tableId}/rows/${rid}`
        const body = {
            "content": {
                "chat": message_recall,
                "sessionID": sessionId
            }
        };
        console.log(process.env.JIGX_PAT)
        
        const rsp = await fetch(post_url, {
            method: "PUT",
            headers: {
                "Authorization": `Bearer ${process.env.JIGX_PAT}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body)
        });
        console.log(await rsp.json());

        if (!rsp.ok) {
            throw new Error(`postChatDD failed with status ${rsp.status}`)
        };
    };

    return { saveChat }
};
export default postChatDD;