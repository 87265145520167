import React, { useState } from 'react';
import yaml from 'js-yaml';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { xonokai } from 'react-syntax-highlighter/dist/esm/styles/prism';
import CopyToClipboard from 'react-copy-to-clipboard';
import { HiOutlineClipboardCopy, HiOutlineCheck } from 'react-icons/hi';

export const useChatAPI = (setMessages, setMessageRecall) => {
    const [copied, setCopied] = useState(false);
    const [copyMessage, setCopyMessage] = useState('');

    const handleCopy = () => {
        setCopied(true);
        setCopyMessage('Copied!');
    
        // Reset copied state for the next response
        setTimeout(() => {
          setCopied(false);
          setCopyMessage('');
        }, 2000); // Reset after 2 seconds (adjust as needed)
      };

    const sendMessage = async (inputText, messages, message_recall) => {
        const prompt = {
            role: "user",
            content: inputText,
        };
    
        // set messages for UI
        setMessages([...messages, prompt]);
        // set message for openAI recall
        const tempMessageRecall = [...message_recall, prompt];
        console.log(tempMessageRecall);
        console.log(process.env);
    
        // call to opneAi in order to get response to prompt
        const response = await fetch("https://api.openai.com/v1/chat/completions", {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                model: "ft:gpt-3.5-turbo-0613:jigx:jo-3-5-turbo-1000:8A6FLsGw",
                messages: tempMessageRecall
                }),
        });

        if (!response.ok) {
            throw new Error(`useChatAPI failed with status ${response.status}`);
        }

        const data = await response.json();
        const res = data.choices[0].message.content;
        console.log(res)

        // call to backend to convert the json to YAML
        const yamlResponse = async (res) => {
            try {
                const yamlData = yaml.dump(res)

                // set chat for UI
                setMessages((messages) => [
                    ...messages,
                    {
                        role: "assistant",
                        content: (
                        <div className="yaml-container">
                            <div className="language-indicator">YAML</div>
                            <div className="yaml-output">
                            <SyntaxHighlighter language="yaml" style={xonokai}>
                                {yamlData}
                            </SyntaxHighlighter>
                            </div>
                            <div className="copy-button">
                            <CopyToClipboard
                                text={yamlData}
                                onCopy={handleCopy}
                            >
                                <i>
                                {copied ? (
                                    <span>
                                    <HiOutlineCheck />
                                    {copyMessage}
                                    </span>
                                ) : (
                                    <span>
                                    <HiOutlineClipboardCopy />
                                    {copyMessage}
                                    </span>
                                )}
                                </i>
                            </CopyToClipboard>
                            </div>
                        </div>
                        ),
                    },
                ]);
            } catch (error) {
                console.error('Error converting json to yaml', error);
            }
        }
        

        const updatedMessageRecall = [
            ...message_recall,
            prompt,
            {
                role: "assistant",
                content: res,
            },
        ];

        setMessageRecall(updatedMessageRecall);

        // Return the updated message_recall
        return updatedMessageRecall;
    };

    return { sendMessage };
};
export default useChatAPI;
