import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Header from './components/Header';
import Textbox from './components/Textbox'; 
import MessageList from './components/MessageList';
import './styles/App.css'; 
import './styles/Textbox.css';
import './styles/Messages.css';
import useChatAPI from './hooks/useChatAPI';
import postChatDD from './hooks/postChatDD';


export default function App() {
  const [inputText, setInputText] = useState("");
  const [messages, setMessages] = useState([{"role": 'assistant', "content": 'How may I help you?'}]);
  const [message_recall, setMessageRecall] = useState([])
  const [sessionId, setSessionId] = useState('');
  // const [history, setHistory] = useState([]);

  // initialize sendMessage hook
  const { sendMessage } = useChatAPI(setMessages, setMessageRecall);
  // intialize newChat hook
  const { saveChat } = postChatDD();

  useEffect(() => {
    setSessionId(uuidv4());
  }, []);

  // call on sendMessage hook
  const handleSendMessage = async () => {
    try {
      console.log(message_recall)
      // Capture the updated message_recall
      const updatedMessageRecall = await sendMessage(inputText, messages, message_recall);

      // Pass the updated message_recall directly to saveChat
      // await saveChat(updatedMessageRecall, sessionId);
      setInputText("");
    } catch (error) {
      console.error(error)
    }
  };

  const handleNewChat = async () => {
    try {
      await saveChat(message_recall, sessionId);
      console.log('New chat working in handleNewChat')
      setMessageRecall([])
      setMessages([{"role": 'assistant', "content": 'How may I help you?'}]);
      setSessionId(uuidv4());
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <Header />
      </header>
      
      <div className="message-section">
        <MessageList messages={messages}/>
        <Textbox 
          value={ inputText }
          onChange={(e) => setInputText(e.target.value)}
          onClick={inputText ? handleSendMessage : undefined}
          onNewChatClick = {handleNewChat}
        />
      </div>
    </div>
  );
}